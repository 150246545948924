import { useEffect, useState } from 'react'
import styles from '../../App.module.css'
import { getStaticProxyConfig, setStaticProxyConfig } from '../../services/api'
import { WarningModal } from './WarningModal'

export const StaticProxy = () => {
    const [confirmationModal, setconfirmationModal] = useState<boolean>(false)
    const [confirmationWSModal, setconfirmationWSModal] =
        useState<boolean>(false)
    const [disableButtons, setDisableButtons] = useState<boolean>(false)
    const [allPliveFamilies, setAllPliveFamilies] = useState<
        { family: string; count: number }[]
    >([])
    const [unmarkedFamilies, setUnmarkedFamilies] = useState<number>(0)
    const [proxyFamilies, setProxyFamilies] = useState<string[]>([])
    const [proxyFamiliesWS, setProxyFamiliesWS] = useState<string[]>([])
    const [proxyAll, setProxyAll] = useState<boolean>()
    const [proxyWSAll, setProxyWSAll] = useState<boolean>()

    useEffect(() => {
        const fetchData = async () => {
            const staticProxy = await getStaticProxyConfig()
            if (staticProxy) {
                setProxyFamilies(staticProxy.proxyFamilies)
                setProxyFamiliesWS(staticProxy.proxyFamiliesWS)
                setAllPliveFamilies(
                    staticProxy.allFamilies.filter((x: any) => !!x.family)
                )
                setUnmarkedFamilies(
                    staticProxy.allFamilies
                        .filter((x: any) => !x.family)
                        .reduce((acc: number, x: any) => acc + x.count, 0)
                )
                setProxyAll(staticProxy.proxyAll)
                setProxyWSAll(staticProxy.proxyWSAll)
            }
        }
        fetchData()
    }, [])

    const onFamilyChange = (family: string) => {
        setProxyFamilies((prevFamilies) =>
            prevFamilies.includes(family)
                ? prevFamilies.filter((x) => x !== family)
                : [...prevFamilies, family]
        )
    }

    const onFamilyWSChange = (family: string) => {
        setProxyFamiliesWS((prevFamilies) =>
            prevFamilies.includes(family)
                ? prevFamilies.filter((x) => x !== family)
                : [...prevFamilies, family]
        )
    }

    return (
        <div className={styles.main}>
            <div className={styles.title_and_description}>
                <header>Static Proxy</header>
                <p>
                    - Use this to configure which plive family uses this static
                    proxy instead of residential
                </p>
            </div>

            {/* Currently not used. Needs to be redone to support account level selection along with city selectio
              https://github.com/hyplabs/betnet/issues/1111
             */}
            {/*
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Families Enabled (Buckeye1, Buckeye2, 1BV are not included)
                </div>
                {allPliveFamilies && (
                    <div className={styles.section_content}>
                        <div className={styles.table_container}>
                            <table className={styles.table}>
                                <tbody className={styles.scrollable}>
                                    {allPliveFamilies
                                        .filter(
                                            (_f) =>
                                                ![
                                                    'Buckeye1',
                                                    'Buckeye2',
                                                    '1BV',
                                                ].includes(_f.family)
                                        )
                                        .map((_f) => (
                                            <tr key={_f.family}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        defaultChecked={proxyFamilies.includes(
                                                            _f.family
                                                        )}
                                                        onChange={(e) => {
                                                            onFamilyChange(
                                                                _f.family
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td className={styles.max}>
                                                    {_f.family}({_f.count})
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <b>Unmarked Families: {unmarkedFamilies}</b>
                        <div>
                            <b>Enabled for all</b>
                            <input
                                type="checkbox"
                                defaultChecked={proxyAll}
                                checked={proxyAll}
                                onChange={(e) => {
                                    console.log(
                                        'proxy all',
                                        e.target.checked,
                                        proxyAll
                                    )
                                    setProxyAll(!!e.target.checked)
                                }}
                            />
                        </div>
                        <button
                            className={styles.flat}
                            disabled={disableButtons}
                            onClick={() => setconfirmationModal(true)}
                        >
                            Save
                        </button>
                        {confirmationModal && (
                            <WarningModal
                                onClose={() => setconfirmationModal(false)}
                                message={
                                    'Are you sure you want to make these changes? These will affect all users on the bot.'
                                }
                                onConfirm={() => {
                                    setDisableButtons(true)
                                    setStaticProxyConfig({
                                        proxyFamilies,
                                        proxyAll,
                                    })
                                    setconfirmationModal(false)
                                    setTimeout(
                                        () => setDisableButtons(false),
                                        1000
                                    )
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            */}
            {/* Websocket */}
            <div className={styles.title_and_description}>
                <header>Static Proxy for websocket data</header>
                <p>
                    - Use residential proxy for the rest
                </p>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>Families Enabled</div>
                {allPliveFamilies && (
                    <div className={styles.section_content}>
                        <div className={styles.table_container}>
                            <table className={styles.table}>
                                <tbody className={styles.scrollable}>
                                    {allPliveFamilies.map((_f) => (
                                        <tr key={_f.family}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={proxyFamiliesWS.includes(
                                                        _f.family
                                                    )}
                                                    onChange={(e) => {
                                                        onFamilyWSChange(
                                                            _f.family
                                                        )
                                                    }}
                                                />
                                            </td>
                                            <td className={styles.max}>
                                                {_f.family}({_f.count})
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <b>Unmarked Families: {unmarkedFamilies}</b>
                        <div>
                            <b>Enabled for all</b>
                            <input
                                type="checkbox"
                                checked={proxyWSAll}
                                defaultChecked={proxyWSAll}
                                onChange={(e) => {
                                    setProxyWSAll(!!e.target.checked)
                                }}
                            />
                        </div>
                        <button
                            className={styles.flat}
                            disabled={disableButtons}
                            onClick={() => setconfirmationWSModal(true)}
                        >
                            Save
                        </button>
                        {confirmationWSModal && (
                            <WarningModal
                                onClose={() => setconfirmationWSModal(false)}
                                message={
                                    'Are you sure you want to make these changes? These will affect all users on the bot.'
                                }
                                onConfirm={() => {
                                    setDisableButtons(true)
                                    setStaticProxyConfig({
                                        proxyFamiliesWS,
                                        proxyWSAll,
                                    })
                                    setconfirmationWSModal(false)
                                    setTimeout(
                                        () => setDisableButtons(false),
                                        1000
                                    )
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
