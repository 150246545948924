import styles from './BetModal.module.css'
import { IoMdClose } from 'react-icons/io'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Context } from '../../../utils/context'
import { placeBets } from '../../../services/api'
import { Modal } from '../../../components/Modal'
import { ACCOUNT_STATUS } from '../../accounts/types'
import { formatTeam } from '../helpers'
import { OddsIF, GameIF } from '../types'

interface PropsIF {
    odds: OddsIF | undefined
    game: GameIF
    highlight: boolean
    onClose: () => void
}

export const BetModal = (props: PropsIF) => {
    const { odds, game, highlight, onClose } = props
    const { accounts, setBetNotification } = useContext(Context)

    const availableAccounts = useMemo(
        () =>
            accounts &&
            accounts.filter(
                (a) =>
                    a.accountProviders
                        .map((ap) => [
                            ap.provider.id,
                            ap.provider.scraperProviderId,
                        ])
                        .flat()
                        .includes(game.provider.id) &&
                    a.status === ACCOUNT_STATUS.ACTIVE &&
                    !a.isScraperAccount
            ),
        [accounts, game.provider.id]
    )
    const tags = useMemo(
        () => ({
            tags: availableAccounts
                ? ([
                      ...new Set(availableAccounts.map((a) => a.tags).flat()),
                  ].filter((t) => t) as string[])
                : [],
            family: availableAccounts
                ? ([...new Set(availableAccounts.map((a) => a.family))].filter(
                      (t) => t
                  ) as string[])
                : [],
        }),
        [availableAccounts]
    )

    const [selectedTags, setSelectedTags] = useState<{
        family: string[]
        tags: string[]
    }>({ family: [], tags: [] })
    const selectedAccounts = availableAccounts
        ? selectedTags.tags.length === 0 && selectedTags.family.length === 0
            ? availableAccounts
            : availableAccounts.filter(
                  (a) =>
                      a.tags.some((t) => selectedTags.tags.includes(t)) ||
                      (a.family && selectedTags.family.includes(a.family))
              )
        : []
    const [amount, setAmount] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [lineInput, setLineInput] = useState('')

    const onFilterButtonClick = useCallback(
        (label: string, type: keyof typeof tags | 'All') => {
            if (type === 'All') setSelectedTags({ tags: [], family: [] })
            else if (selectedTags[type].includes(label))
                setSelectedTags({
                    ...selectedTags,
                    [type]: selectedTags[type].filter((p) => p !== label),
                })
            else
                setSelectedTags({
                    ...selectedTags,
                    [type]: [...selectedTags[type], label],
                })
        },
        [selectedTags]
    )

    const FilterButton = useCallback(
        (props: { label: string; type: keyof typeof tags | 'All' }) => {
            const { label, type } = props

            return (
                <div
                    className={`${styles.button} ${
                        label === 'All' && styles.button_all
                    } ${type === 'tags' && styles.button_tag} ${
                        type === 'family' && styles.button_family
                    } ${
                        selectedTags.tags.includes(label) &&
                        type === 'tags' &&
                        styles.selected_tag
                    } ${
                        selectedTags.family.includes(label) &&
                        type === 'family' &&
                        styles.selected_family
                    } ${
                        selectedTags.tags.length === 0 &&
                        selectedTags.family.length === 0 &&
                        label === 'All' &&
                        styles.selected_all
                    }`}
                    onClick={() => onFilterButtonClick(label, type)}
                >
                    {label}
                </div>
            )
        },
        [onFilterButtonClick, selectedTags]
    )

    const placeBet = async () => {
        if (odds) {
            setIsLoading(true)
            setError('')
            const res = await placeBets(
                {
                    ...odds,
                    line: parseInt(lineInput.replace('+', '')) || odds.line,
                },
                selectedAccounts.map((a) => a.id),
                Number(amount?.toFixed(2))
            )
            if (!res) {
                setError('Error placing bet. Try again later')
            } else {
                setBetNotification(true)
                onClose()
            }
            setIsLoading(false)
        }
    }

    return (
        <Modal onClose={onClose}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <div
                    className={styles.modal}
                    onMouseDown={(e: React.MouseEvent) => {
                        e.stopPropagation()
                    }}
                >
                    <div className={styles.header}>
                        {formatTeam(game.awayTeam, game.homeTeam)}
                        <IoMdClose
                            className={styles.close}
                            size={18}
                            onMouseDown={onClose}
                        />
                    </div>
                    <p>{game.league}</p>
                    <div className={styles.details}>
                        {odds ? (
                            <>
                                <div className={styles.details_row}>
                                    <p>{odds.category}</p>
                                    <p>{odds.group}</p>
                                </div>
                                <div className={styles.details_row}>
                                    <label>{odds.description}</label>
                                    <input
                                        id="line"
                                        name="line"
                                        type="text"
                                        className={
                                            !lineInput && highlight
                                                ? styles.highlight
                                                : ''
                                        }
                                        value={lineInput}
                                        placeholder={`${
                                            odds.line > 0 ? '+' : ''
                                        }${odds.line}`}
                                        onChange={(e) => {
                                            if (
                                                /^[+-]?(\d+)?$/.test(
                                                    e.target.value
                                                )
                                            )
                                                setLineInput(e.target.value)
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.details_row}>
                                Odds unavailable.
                            </div>
                        )}
                    </div>
                    {availableAccounts && (
                        <div className={styles.accounts}>
                            <div className={styles.filters}>
                                <FilterButton
                                    key="All"
                                    label="All"
                                    type="All"
                                />
                                {tags.family.map((tag) => (
                                    <FilterButton
                                        key={`${tag}_family`}
                                        label={tag}
                                        type="family"
                                    />
                                ))}
                                {tags.tags.map((tag) => (
                                    <FilterButton
                                        key={`${tag}_tag`}
                                        label={tag}
                                        type="tags"
                                    />
                                ))}
                            </div>
                            <div className={styles.sites}>
                                {selectedAccounts.length === 0 ? (
                                    <p style={{ color: 'var(--danger)' }}>
                                        No sites selected.
                                    </p>
                                ) : (
                                    <p
                                        style={{
                                            color:
                                                selectedTags.tags.length ===
                                                    0 &&
                                                selectedTags.family.length === 0
                                                    ? 'var(--text2)'
                                                    : selectedTags.family
                                                          .length === 0
                                                    ? 'var(--text3)'
                                                    : 'var(--text4)',
                                        }}
                                    >
                                        {`${selectedAccounts[0].website} ${
                                            selectedAccounts.length > 1
                                                ? `+ ${
                                                      selectedAccounts.length -
                                                      1
                                                  } others`
                                                : ''
                                        }`}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={styles.amount}>
                        {'$ '}
                        <input
                            id="amount"
                            name="amount"
                            type="number"
                            placeholder="Autobet"
                            value={amount ?? ''}
                            onChange={(e) =>
                                e.target.value
                                    ? setAmount(
                                          +parseFloat(e.target.value).toFixed(2)
                                      )
                                    : setAmount(null)
                            }
                            autoFocus
                        />
                    </div>
                    <div className={styles.submit}>
                        {error && <span className={styles.error}>{error}</span>}
                        <button
                            type="submit"
                            onClick={placeBet}
                            disabled={
                                selectedAccounts.length === 0 || isLoading
                            }
                        >
                            {!isLoading ? 'Place Bet' : '...'}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
